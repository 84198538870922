<template>
  <div class="about">
    <!-- about section start -->
    <section class="mt-n2" else>
      <div class="container">
        <div class="row">
          <div class="col-md-12 text-justify">
            <h2 class="text-left mb-3 mt-4">About Teka</h2>
            <p>
              <b>Vedhaank Digital Private Limited, operated as TEKA, Brand Name.</b> TEKA is a unique social
              engineering
              business with offline to online to offline O to O-to-O hybrid business model which is making offline
              merchants to go online and get sales from offline as well. It helps our business fraternity to become
              prosperous. A merchant, trader manufacturer, distributor all of them have an option to sell their products
              and services to the esteemed customers of TEKA. Teka has a unique cash back system one of its kind in the
              world with which every purchase of a customer will get them scratch card discount and an assured cash
              back.
            </p>
            <p>
              TEKA is a huge field of future growth. No other sales channel offers entrepreneurs so many opportunities
              at such a low risk. TEKA’s business model that allows independent entrepreneurs to sell their products or
              services directly to consumers using its platform. What's more, it allows entrepreneurs to benefit from a
              growing community of sales partners, who also recommend and sell products and services to customers and
              bring new sales partners on board so that they can do the same. If you can manage to keep on applying this
              duplication method while also putting your leadership skills to the test, then you will have built the
              foundations you need for a successful business. Merchants, vendors, manufacturing companies get a huge
              support from TEKA’s platform to publish and present their products and services at one go. With so much of
              customer base for offering through this website and mobile application the business fraternity gets easy
              sales and turnover.
            </p>
            <p>
              TEKA offers unique benefits to more than 2lakh shoppers across the state as well as innovative customer
              retention programmers to more than 5000 business Partners. Shoppers benefit by receiving Cashback and
              Shopping Points for every purchase. While merchants attract more customers by offering those benefits.
            </p>
            <p>With Team's business model of integrating community building, huge customer bases can create volumes of
              turnover within no time, which allows them to tap into the tremendous purchasing power of the Shopping
              Community With an enormous product range, straightforward relations with participating merchants and fair
              policies for customers and businesses, the online and offline marketplace teka.net.in takes online and
              offline shopping to another dimension. Whether that be online merchants who want to expand their reach or
              customers who want to easily find the best prices for their desired products, teka.net.in has everyone
              covered.</p>
            <p>Get started without any technical hurdles. It's really simple to turn your offline shop convert into
              online shop as well into a part of the TEKA's Cashback Shopping Community. Our internal affiliate network
              will quickly and simply connect your online and offline shop, without any technical hurdles, so that it is
              available to shoppers in no time at all. More attention for your offers by being listed on the TEKA
              website and those of our partnerships, as well as in the TEKA's mobile app, the visibility of your
              company, business will be vastly improved. With Akshaya Patra point system, you will get even more
              attention and will actively be advertised in our newsletter and social media channels.</p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>

export default { 
    data() {
      return {
      isLoading:false
      }
    },
  
  created() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  },
};
</script>
